export const environment = {
    production: true,
    environmentName: 'LOCAL',
    basePath: 'http://localhost:7010/',
    socketPath: 'http://localhost:7010/ws',
    pingPath: 'https://www.cloudflare.com/cdn-cgi/trace',
    analyticsFilename: 'analytics-config-stag.json',
    tokenUrl: 'http://localhost:5500/',
};

// export const localEnvironment = {
//   production: true,
//   googleAnalytics: 'G-D7W6HG0888'
// };
