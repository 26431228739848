import { Route } from "@angular/router";
import { InitialDataResolver } from "app/app.resolvers";
import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { LayoutComponent } from "app/layout/layout.component";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    // { path: '', pathMatch: 'full', redirectTo: 'example' },
    { path: "", pathMatch: "full", redirectTo: "dashboard" },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.

    // { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'example' },
    { path: "signed-in-redirect", pathMatch: "full", redirectTo: "dashboard" },

    // Auth routes for guests
    {
        path: "",
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: "empty"
        },
        children: [
            {
                path: "confirmation-required",
                loadChildren: () => import("app/modules/auth/confirmation-required/confirmation-required.routes")
            },
            {
                path: "forgot-password",
                loadChildren: () => import("app/modules/auth/forgot-password/forgot-password.routes")
            },
            {
                path: "reset-password",
                loadChildren: () => import("app/modules/auth/reset-password/reset-password.routes")
            },
            {
                path: "sign-in",
                loadChildren: () => import("app/modules/auth/sign-in/sign-in.routes")
            }
        ]
    },

    // Auth routes for authenticated users
    {
        path: "",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: "empty"
        },
        children: [
            {
                path: "sign-out",
                loadChildren: () => import("app/modules/auth/sign-out/sign-out.routes")
            },
            {
                path: "unlock-session",
                loadChildren: () => import("app/modules/auth/unlock-session/unlock-session.routes")
            }
        ]
    },

    // Landing routes
    {
        path: "",
        component: LayoutComponent,
        data: {
            layout: "empty"
        },
        children: []
    },

    // Admin routes
    {
        path: "",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver
        },
        children: [
            {
                path: "dashboard",
                loadChildren: () => import("app/modules/admin/main/main.routes")
            },
            {
                path: "license",
                loadChildren: () => import("app/modules/admin/license-management/license-management.routes")
            },
            {
                path: "configurations",
                loadChildren: () => import("app/modules/admin/configurations/configurations.routes")
            },
            {
                path: "users",
                loadChildren: () => import("app/modules/admin/users/users.routes")
            },
            {
                path: "organization",
                loadChildren: () => import("app/modules/admin/organization/organization.routes")
            },
            // {
            //     path: 'externalservices',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/admin/external-services/external-services.routes'
            //         ),
            // },
            {
                path: "connectors",
                loadChildren: () => import("app/modules/admin/Connector/connectors/connectors.routes")
            },
            {
                path: "connectors/details",
                loadChildren: () => import("app/modules/admin/Connector/connector-details/connector-details.routes")
            },
            {
                path: "service-plan",
                loadChildren: () => import("app/modules/admin/serviceplan/serviceplan.routes")
            },
            {
                path: "plan-details",
                loadChildren: () => import("app/modules/admin/service-plan-detail/service-plan-detail.routes")
            },
            {
                path: "roles",
                loadChildren: () => import("app/modules/admin/roles/roles.routes")
            },
            {
                path: "roles/detail",
                loadChildren: () => import("app/modules/admin/role-details/role-details.routes")
            },
            {
                path: "administrators",
                loadChildren: () => import("app/modules/admin/administrators/administrators.routes")
            },
            {
                path: "administrators/detail",
                loadChildren: () => import("app/modules/admin/administrator-details/administrator-details.routes")
            },
            {
                path: "activity-logs",
                loadChildren: () => import("app/modules/admin/activity-logs/activity-logs.routes")
            },
            {
                path: "audit-logs",
                loadChildren: () => import("app/modules/admin/audit-logs/audit-logs.routes")
            },
            {
                path: "package",
                loadChildren: () => import("app/modules/admin/package/package.routes")
            },
            {
                path: "package-details",
                loadChildren: () => import("app/modules/admin/package-details/package-detail.routes")
            }
        ]
    },
    // temp routes
    {
        path: "",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver
        },
        children: []
    },

    // document routes
    {
        path: "",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver
        },
        children: []
    },

    // Settings routes
    {
        path: "",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver
        },
        children: []
    },

    // Error routes
    {
        path: "",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: "empty"
        },
        resolve: {
            initialData: InitialDataResolver
        },
        children: [
            {
                path: "",
                children: [
                    {
                        path: "400",
                        loadChildren: () => import("app/modules/error/error-400/error-400.routes")
                    },
                    {
                        path: "401",
                        loadChildren: () => import("app/modules/error/error-401/error-401.routes")
                    },
                    {
                        path: "403",
                        loadChildren: () => import("app/modules/error/error-403/error-403.routes")
                    },
                    {
                        path: "404",
                        loadChildren: () => import("app/modules/error/error-404/error-404.routes")
                    },
                    {
                        path: "500",
                        loadChildren: () => import("app/modules/error/error-500/error-500.routes")
                    }
                ]
            },
            { path: "**", redirectTo: "/404" }
        ]
    }
];
