import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { GeneralSettingsService } from './core/generalSettings/general.settings.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
    /**
     * Constructor
     */
    constructor(
        private _generalSettingsService: GeneralSettingsService,
    ) { }
    ngOnInit(): void {
        this.getAppName();
    }

    getAppName() {
        this._generalSettingsService.getAppName('APP_NAME').subscribe(response => {
            if (response) {
                this._generalSettingsService.setAppName(response);
                localStorage.setItem('appName', response || '');
            }
        });
    }

}
